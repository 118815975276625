var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.$options.components['profile-show-edit-profile'] ? [_c('profile-show-edit-profile', {
    attrs: {
      "editKey": false
    }
  })] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex mt-2 mb-2 justify-content-between"
  }, [_c('h4', [_vm._v("Why TEKA?")])]), _c('b-row', [_c('div', {
    staticClass: "main-box pt-4 pr-3 pl-3 mt-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "content-div p-3"
  }, [_c('div', {
    staticClass: "d-flex mb-3 top-box"
  }, [_c('img', {
    attrs: {
      "src": "/img/reason_icon1.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "mb-0 ml-3 mt-3"
  }, [_vm._v("Growth")])]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum omnis similique quibusdam ab. ")])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "content-div p-3"
  }, [_c('div', {
    staticClass: "d-flex mb-3 top-box"
  }, [_c('img', {
    attrs: {
      "src": "/img/reason_icon1.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "mb-0 ml-3 mt-3"
  }, [_vm._v("Growth")])]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum omnis similique quibusdam ab. ")])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "content-div p-3"
  }, [_c('div', {
    staticClass: "d-flex mb-3 top-box"
  }, [_c('img', {
    attrs: {
      "src": "/img/reason_icon1.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "mb-0 ml-3 mt-3"
  }, [_vm._v("Growth")])]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum omnis similique quibusdam ab. ")])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "content-div p-3"
  }, [_c('div', {
    staticClass: "d-flex mb-3 top-box"
  }, [_c('img', {
    attrs: {
      "src": "/img/reason_icon1.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "mb-0 ml-3 mt-3"
  }, [_vm._v("Growth")])]), _c('p', [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum omnis similique quibusdam ab. ")])])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "text-center mt-3 mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary pr-5 pl-5",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "button-text pr-5 pl-5",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('registration-form');
      }
    }
  }, [_vm._v("Become Seller Now")])])])], 1)], 1)])], 1), _c('b-modal', {
    attrs: {
      "id": "registration-form",
      "size": "lg",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    }
  }, [_c('v-container', {
    staticClass: "p-3"
  }, [_c('v-row', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("Seller Self-Registration")])]), _c('v-form', [_c('v-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.marketOptions
    },
    model: {
      value: _vm.selectedMarket,
      callback: function callback($$v) {
        _vm.selectedMarket = $$v;
      },
      expression: "selectedMarket"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.categoryOptions
    },
    model: {
      value: _vm.selectedCategory,
      callback: function callback($$v) {
        _vm.selectedCategory = $$v;
      },
      expression: "selectedCategory"
    }
  })], 1)], 1), _c('v-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "type": "number",
      "label": "GST no*"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "type": "password",
      "label": "Email ID*"
    }
  })], 1)], 1), _c('v-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.options
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1), _c('v-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "label": "Username*"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "type": "password",
      "label": "Password*"
    }
  })], 1)], 1), _c('v-row', [_c('b-col', {
    staticClass: "text-center mt-3",
    attrs: {
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mr-3 pr-5 pl-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('registration-form');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }, [_vm._v("Reset")])]), _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "button-text pr-5 pl-5"
  }, [_vm._v("Register Now")])])])], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
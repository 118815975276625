<template>
  <div class="profile">
    <div class="container">
      <b-row class="mb-4">
        <b-col md="6">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile :editKey="false" />
          </template>
        </b-col>
        <b-col md="6">
          <div class="d-flex mt-2 mb-2 justify-content-between">
            <h4>Why TEKA?</h4>
          </div>
          <b-row>
            <div class="main-box pt-4 pr-3 pl-3 mt-2">
              <b-row>
                <b-col md="6">
                  <div class="content-div p-3">
                    <div class="d-flex mb-3 top-box">
                      <img src="/img/reason_icon1.png" alt />
                      <p class="mb-0 ml-3 mt-3">Growth</p>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Rerum omnis similique quibusdam ab.
                    </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="content-div p-3">
                    <div class="d-flex mb-3 top-box">
                      <img src="/img/reason_icon1.png" alt />
                      <p class="mb-0 ml-3 mt-3">Growth</p>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Rerum omnis similique quibusdam ab.
                    </p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <div class="content-div p-3">
                    <div class="d-flex mb-3 top-box">
                      <img src="/img/reason_icon1.png" alt />
                      <p class="mb-0 ml-3 mt-3">Growth</p>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Rerum omnis similique quibusdam ab.
                    </p>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="content-div p-3">
                    <div class="d-flex mb-3 top-box">
                      <img src="/img/reason_icon1.png" alt />
                      <p class="mb-0 ml-3 mt-3">Growth</p>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Rerum omnis similique quibusdam ab.
                    </p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="text-center mt-3 mb-4">
                  <button type="button" class="btn btn-primary pr-5 pl-5">
                    <span
                      class="button-text pr-5 pl-5"
                      @click="$bvModal.show('registration-form')"
                      >Become Seller Now</span
                    >
                  </button>
                </b-col>
              </b-row>
            </div>
          </b-row>
        </b-col>
        <b-modal
          id="registration-form"
          size="lg"
          centered
          hide-header
          hide-footer
        >
          <v-container class="p-3">
            <v-row class="mb-3">
              <h5>Seller Self-Registration</h5>
            </v-row>
            <v-form>
              <v-row>
                <b-col md="6"
                  ><b-form-select
                    v-model="selectedMarket"
                    :options="marketOptions"
                    class="mt-1"
                  ></b-form-select>
                </b-col>
                <b-col md="6"
                  ><b-form-select
                    v-model="selectedCategory"
                    :options="categoryOptions"
                    class="mt-1"
                  ></b-form-select>
                </b-col>
              </v-row>
              <v-row>
                <b-col md="6"
                  ><v-text-field class="mb-2" type="number" label="GST no*">
                  </v-text-field>
                </b-col>
                <b-col md="6"
                  ><v-text-field class="mb-2" type="password" label="Email ID*">
                  </v-text-field>
                </b-col>
              </v-row>
              <v-row>
                <b-col md="12">
                  <b-form-select
                    v-model="selected"
                    :options="options"
                    class="mt-1"
                  ></b-form-select>
                </b-col>
              </v-row>
              <v-row>
                <b-col md="6">
                  <v-text-field class="mb-2" label="Username*"> </v-text-field>
                </b-col>
                <b-col md="6">
                  <v-text-field class="mb-2" type="password" label="Password*">
                  </v-text-field>
                </b-col>
              </v-row>
              <v-row>
                <b-col md="12" class="text-center mt-3">
                  <button
                    @click="$bvModal.hide('registration-form')"
                    type="button"
                    class="btn btn-outline-primary mr-3 pr-5 pl-5"
                  >
                    <span class="button-text">Reset</span>
                  </button>
                  <button type="button" class="btn btn-primary ml-3">
                    <span class="button-text pr-5 pl-5">Register Now</span>
                  </button>
                </b-col>
              </v-row>
            </v-form>
          </v-container>
        </b-modal>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      marketOptions: [{ text: "Select Market", value: null, disabled: true }],
      categoryOptions: [
        { text: "Select Category", value: null, disabled: true },
      ],
      selectedMarket: null,
      selectedCategory: null,
    };
  },
};
</script>

<style scoped>
.main-box {
  background: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 15px;
  width: 100%;
}
.content-div {
  width: 100%;
  border: 1px solid #ffe2c4;
  background: #fff;
  border-radius: 10px;
}
.top-box p {
  font-weight: 600;
  font-size: 18px;
}
::v-deep #registration-form .custom-select {
  border: none;
  border-bottom: 1px solid #717171;
  border-radius: 0px;
}
</style>
